import './App.css';
import { Nav,Navbar,Form,Container} from 'react-bootstrap';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import {isMobile,isBrowser} from "react-device-detect";
import {Component} from 'react';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PlaceIcon from '@mui/icons-material/Place';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { ThreeCircles } from  'react-loader-spinner';
import * as ls from "local-storage";
import Card from 'react-bootstrap/Card';
import queryString from 'query-string';

class App extends Component{
    
  constructor(props) {
  super(props);
  this.state = {
        nomcontact:'',
        telephonecontact:'',
        mailcontact:'',
        titrecontact:'',
        typeabonnement:0,
        typecallcenter:0,
        typelivedemo:0,
        typesolution:0,
        messagecontact:'',
        generatecaptcha:'',
        captcha:'',
        show: false,
        setShow: false,
        tokenvalue:'',
        reload : false,
        username:'',
        tokenid:'',
        notfound : false,
        lng:'fr',
        width:0,
        height:0,
        scrollpage:0,
        loadingdata:true,
        isFocused: false,
        typeabonnement:0,
        listtypeabonnement:[
          { typeabonnementfr: '---', typeabonnementen: '---', value: 0 },
          { typeabonnementfr: 'Starter', typeabonnementen: 'Starter', value: 1 },
          { typeabonnementfr: 'Business', typeabonnementen: 'Business', value: 2 },
          { typeabonnementfr: 'Enterprise', typeabonnementen: 'Entreprise', value: 3 },
        ],
        typecallcenter:0,
        listtypecallcenter:[
          { callcenterfr: '---', callcenteren: '---',value: 0 },
          { callcenterfr: 'Oui', callcenteren: 'Yes', value: 1 },
          { callcenterfr: 'Non', callcenteren: 'No', value: 2 },
          { callcenterfr: 'Système téléphonique basé sur le cloud', callcenteren: 'Cloud Based Phone System', value: 3 },
        ],

        typelivedemo:0,
        listtypelivedemo:[
          { livedemofr: '---', livedemoen: '---',value: 0 },
          { livedemofr: 'Oui', livedemoen: 'Yes', value: 1 },
          { livedemofr: 'Non', livedemoen: 'No', value: 2 },
        ],

        typesolution:0,
        listtypesolution:[
          { typesolutionfr: '---', typesolutionen: '---',value: 0 },
          { typesolutionfr: 'Proposez moi une solution', typesolutionen: 'Recommend me a solution', value: 1 },
          { typesolutionfr: 'Non', typesolutionen: 'No', value: 2 },
        ],

      };

  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  this.removeloader = this.removeloader.bind(this);
  this.selecttypeabonnement = this.selecttypeabonnement.bind(this);
  this.selecttypecallcenter = this.selecttypecallcenter.bind(this);
  this.selecttypelivedemo = this.selecttypelivedemo.bind(this);
  this.selecttypesolution = this.selecttypesolution.bind(this);
  
      
}

selecttypeabonnement(e) {  
  this.setState({ typeabonnement: e.target.value });
}

selecttypecallcenter(e) {
  this.setState({ typecallcenter: e.target.value });
}

selecttypelivedemo(e) {
  this.setState({ typelivedemo: e.target.value });
}

selecttypesolution(e) {
  this.setState({ typesolution: e.target.value });
}

componentDidMount() {

  let lng = ls.get('lng');

  if(lng === null){
    ls.set('lng','fr');
    this.setState({ lng: 'fr'});
  }else{
    this.setState({ lng: lng});
  }

  var chiffres = '0123456789';
      var min = "abcdefghijklmnopqrstuvwxyz";
      var maj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var special = "$#";
      let pass = '';
      var len_chiffres = chiffres.length;
      var len_min = min.length;
      var len_maj = maj.length;
      var len_special = special.length;
    
      for (let i = 0; i < 2; i++ ) {
        pass += chiffres[Math.floor(Math.random() * len_chiffres)];
      }
    
      for (let i = 0; i < 3; i++ ) {
        pass += min[Math.floor(Math.random() * len_min)];
      }
    
      for (let i = 0; i < 3; i++ ) {
        pass += maj[Math.floor(Math.random() * len_maj)];
      }
    
      for (let i = 0; i < 2; i++ ) {
        pass += special[Math.floor(Math.random() * len_special)];
      }
    
      var melanger = pass.split('').sort(function(){return 0.5-Math.random()}).join('');

      this.setState({ generatecaptcha : melanger});


      window.addEventListener("blur", this.newroute());
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.addEventListener('scroll', this.updateWindowDimensions);
      window.addEventListener('load', this.removeloader);

      this.removeloader();
}

componentWillUnmount() {

  /*
  let lng = ls.get('lng');

  if(lng === null){
    ls.set('lng','fr');
    this.setState({ lng: 'fr'});
  }else{
    this.setState({ lng: lng});
  }
  */

  
  window.removeEventListener('resize', this.updateWindowDimensions);
  window.removeEventListener('scroll', this.updateWindowDimensions);


}


updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight, scrollpage: window.scrollY });
}

removeloader() {

  this.setState({ loadingdata:false});

}


scrolltop(){
  window.scrollTo(0, 0)
}


newroute(){




}

changelangue (typelangue) {

  if(typelangue === "fr"){
    this.setState({ lng:'fr'})
    ls.set('lng','fr');
    window.scrollTo(0, 0);
    window.location.reload();
  }else{
    this.setState({ lng:'en'})
    ls.set('lng','en');
    window.scrollTo(0, 0);
    window.location.reload();
  }

}

typeabonnement (typeabonnement) {

  this.setState({ typeabonnement:typeabonnement})

  window.location.href="#contact"

}


envoiemail() {

  if(this.state.nomcontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir votre nom s'il vous plait.");
      return;

    }else{

      alert("Please enter your name.");
      return;

    }  

  }

  if(this.state.telephonecontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir votre numéro de téléphone.");
      return;

    }else{

      alert("Please enter your phone number.");
      return;

    }  

  }

  if(this.state.mailcontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir votre mail.");
      return;

    }else{

      alert("Please enter your email.");
      return;

    }  

  }

  if (typeof this.state.mailcontact !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
      if (!pattern.test(this.refs.Emailsend.value)) {

        if(this.state.lng === "fr"){

          alert("Veuillez saisir un mail valide s'il vous plait.");
          return;
    
        }else{
    
          alert("Please enter a valid email.");
          return;
    
        }  
    
      }
        
    }

  if(this.state.titrecontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir le sujet de votre information.");
      return;

    }else{

      alert("Please enter the subject of your information.");
      return;

    }  

  }

  if(this.state.titrecontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir le sujet de votre information.");
      return;

    }else{

      alert("Please enter the subject of your information.");
      return;

    }  

  }

  if(this.state.messagecontact.length ==0){

    if(this.state.lng === "fr"){

      alert("Veuillez saisir l'information supllémentaire de votre demande.");
      return;

    }else{

      alert("Please enter additional information for your request.");
      return;

    }  

  }

  if (this.state.captcha.length ==0) {

    if(this.state.lng === "fr"){

      alert("Veuillez saisir le captcha s'il vous plait.");
      return;

    }else{

      alert("Please enter the captcha.");
      return;

    }  

  }


  if (this.state.generatecaptcha !== this.state.captcha) {

    
    if(this.state.lng === "fr"){

      alert("Le captcha saisit est incorrect. Veuillez le rectifier s'il vous plait.");
      return;

    }else{

      alert("The captcha entered is incorrect. Please correct it.");
      return;

    }  

  }

  if (this.state.typeabonnement ==0) {

    if(this.state.lng === "fr"){

      alert("Veuillez séléctionner le type d'abonnement s'il vous plait.");
      return;

    }else{

      alert("Please select subscription type.");
      return;

    }  

  }

  if (this.state.typecallcenter ==0) {

    if(this.state.lng === "fr"){

      alert("Veuillez séléctionner le type de centre d'appel s'il vous plait.");
      return;

    }else{

      alert("Please select the type of call center.");
      return;

    }  

  }

  if (this.state.typelivedemo ==0) {

    if(this.state.lng === "fr"){

      alert("Veuillez séléctionner si vous voulez un live demo s'il vous plait.");
      return;

    }else{

      alert("Please select if you want a live demo.");
      return;

    }  

  }

  if (this.state.typesolution ==0) {

    if(this.state.lng === "fr"){

      alert("Veuillez séléctionner si vous êtes interessé à une centre d'appel.");
      return;

    }else{

      alert("Please select if you are interested in a call center.");
      return;

    }  

  }

  var eltypeabonnement = document.getElementById('typeabonnement');
  var typeabonnementlabel = eltypeabonnement.options[eltypeabonnement.selectedIndex].innerHTML;

  var eltypecallcenter = document.getElementById('typecallcenter');
  var typecallcenterlabel = eltypecallcenter.options[eltypecallcenter.selectedIndex].innerHTML;

  var eltypelivedemo = document.getElementById('typelivedemo');
  var typelivedemolabel = eltypelivedemo.options[eltypelivedemo.selectedIndex].innerHTML;

  var eltypesolution = document.getElementById('typesolution');
  var typesolutionlabel = eltypesolution.options[eltypesolution.selectedIndex].innerHTML;

  var datacenter = "";

  if(this.state.lng === "fr"){

    datacenter += "Type abonnement : " + typeabonnementlabel+"<br/>";
    datacenter += "Type de centre d'appel : " + typecallcenterlabel+"<br/>";
    datacenter += "Live demo : " + typelivedemolabel+"<br/>";
    datacenter += "Solution pour centre d'appel : " + typesolutionlabel+"<br/>";

  }else{

    datacenter += "Abonnement: " + typeabonnementlabel+"<br/>";
    datacenter += "Call center : " + typecallcenterlabel+"<br/>";
    datacenter += "Live demo : " + typelivedemolabel+"<br/>";
    datacenter += "Call center solution: " + typesolutionlabel+"<br/>";

  }  

  fetch("https://webservices.unsaidapps.com/mailer/contact.php",{
                      
      method:'post', 
      headers: {'Content-Type':'application/x-www-form-urlencoded'},                   
      body: queryString.stringify({
          nom: this.state.nomcontact,
          telephone:this.state.telephonecontact,
          email: this.state.mailcontact,
          titre: this.state.titrecontact,
          datacenter:datacenter,
          lng:this.state.lng,
          message: this.state.messagecontact.replace(/(\r\n|\n)/g, "<br/>"),

      })

  })
      .then(data => data.text())
        .then((text) => { 

          var chiffres = '0123456789';
          var min = "abcdefghijklmnopqrstuvwxyz";
          var maj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          var special = "?!$#";
          let pass = '';
          var len_chiffres = chiffres.length;
          var len_min = min.length;
          var len_maj = maj.length;
          var len_special = special.length;
        
          for (let i = 0; i < 2; i++ ) {
            pass += chiffres[Math.floor(Math.random() * len_chiffres)];
          }
        
          for (let i = 0; i < 3; i++ ) {
            pass += min[Math.floor(Math.random() * len_min)];
          }
        
          for (let i = 0; i < 3; i++ ) {
            pass += maj[Math.floor(Math.random() * len_maj)];
          }
        
          for (let i = 0; i < 2; i++ ) {
            pass += special[Math.floor(Math.random() * len_special)];
          }
        
          var melanger = pass.split('').sort(function(){return 0.5-Math.random()}).join('');

          this.setState({
            nomcontact:'',
            telephonecontact:'',
            mailcontact:'',
            titrecontact:'',
            typeabonnement:0,
            typecallcenter:0,
            typelivedemo:0,
            typesolution:0,
            messagecontact:'',
            captcha:'',
            generatecaptcha : melanger,
          });

               //NotificationManager.success('Message envoyé', 'Succès',3000);

          if(this.state.lng === "fr"){

            alert("Mail envoyé.")
        
          }else{
        
            alert("Mail sent.")
        
          }  

        }).catch(function (error) {
          alert(error)
          //NotificationManager.warning(error, 'Erreur',5000);
        });

}

render() {

  return (

            <div className='noselect' >

                <div style={{position:"fixed", width:"100%", top:0, zIndex: '1000'}} >
                  <Navbar collapseOnSelect expand="lg" className="navbar-edit navbar-fixed-top menu w-100" style={{backgroundColor: this.state.width<1000 ?"#333333":"transparent" }}>
                    <Container fluid style={{backgroundColor: this.state.scrollpage>200 ?"#333333":"transparent", marginTop:this.state.scrollpage>200 ?"-10px":"0"}}>
                      <Navbar.Brand href="#"><img src={process.env.PUBLIC_URL +'logounsaid.png'} style={{width:"100px", height:"62.5px"}} alt="Unsaid"/></Navbar.Brand>
                      <Navbar.Toggle aria-controls="navbarScroll" />
                      <Navbar.Collapse id="navbarScroll">
                      <Nav className="justify-content-center" style={{ width: "100%", marginRight:"100px" }}>
                        
                          <Nav.Link href="#accueil" >{this.state.lng === "fr"?  "Accueil": "Home"}</Nav.Link>
                          <Nav.Link href="#solution">{this.state.lng === "fr"?  "Notre solution": "Our solution"}</Nav.Link>
                          <Nav.Link href="#tarification">{this.state.lng === "fr"?  "Tarif": "Pricing"}</Nav.Link>
                          <Nav.Link href="#contact">{this.state.lng === "fr"?  "Contact": "Contact"}</Nav.Link>
                          <Nav.Link onClick={() => this.changelangue('fr')}><img src={process.env.PUBLIC_URL +'fr.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/>  French</Nav.Link>
                          <Nav.Link onClick={() => this.changelangue('en')}><img src={process.env.PUBLIC_URL +'eu.png'} style={{width:"30px", height:"20px"}} alt="Unsaid"/>  English</Nav.Link>
                          
                          {/*
                            <Nav.Link href="https://www.facebook.com/unsaidai" target="_blank"><FacebookIcon style={{fontSize:'30' , color: 'white', marginLeft:20, marginBottom:6}}/></Nav.Link>
                            <Nav.Link href="https://twitter.com/unsaid_ai" target="_blank"><TwitterIcon style={{fontSize:'30' , color: 'white', marginLeft:20, marginBottom:6}}/></Nav.Link>
                            <Nav.Link href="https://www.linkedin.com/company/unsaid-ai" target="_blank" ><LinkedInIcon style={{fontSize:'30', color: 'white', marginLeft:20, marginBottom:6}}/></Nav.Link>
                            <Nav.Link href="https://www.instagram.com/unsaid_ai/" target="_blank"><InstagramIcon style={{fontSize:'30', color: 'white', marginLeft:20, marginBottom:6}}/></Nav.Link>
                            <Nav.Link href="https://www.youtube.com/channel/UCA5BDGMytHfnK19bhAD_vVg" target="_blank"><YouTubeIcon style={{fontSize:'30', color: 'white', marginLeft:20, marginBottom:6}}/></Nav.Link>
                          */}

                        </Nav>
                        
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>

                </div>  

                <div id="accueil" className="headermenu" style={{backgroundImage: "url(" + process.env.PUBLIC_URL + "pexels-anna-shvets-3727464-scaled.jpg)",  backgroundSize: isBrowser ? 'cover': 'cover',  backgroundRepeat: 'no-repeat', width:'100%', height: isBrowser? '100vh':'100vh', marginTop:"-100px"}}>
                    
                    <div className="d-flex flex-column">

                      <Zoom left>
                        <p style={{fontSize: isBrowser? "4rem":"2rem",color:"white", textAlign:"center"}}>{this.state.lng === "fr"?  "Améliorez la satisfaction client grâce à l'aide de l'IA.": "Increase Customer Satisfaction With the Help of AI"}</p>
                      </Zoom>
          
                    
          
                      <Zoom right >
                        <button className="btn btn-primary btndemo center" onClick={(e) => {e.preventDefault(); window.location.href="#tarification";}} style={{width:'200px', height:'50px', borderRadius:"25px", marginTop:"50px"}}>{this.state.lng === "fr"?  "Réserver un demo": "Book a Demo"}</button>
                      </Zoom>

                    </div>

                </div> 

                <div className='loaderdata' >

                  {this.state.loadingdata === true?

                    <div style={{textAlign:"center"}}>
                      <ThreeCircles
                        height="150"
                        width="150"
                        wrapperStyle={{fontSize:"10px"}}
                        wrapperClass=""
                        visible={this.state.loadingdata}
                        outerCircleColor="#f9c922"
                        innerCircleColor="#e74c3c"
                        middleCircleColor="#2A83E8"
                        />
                    </div>


                    :

                    null

                    }

                </div>


                <div id="solution">

                  <Zoom>
                    <p style={{fontWeight:"bold", fontSize:"50px", textAlign:"center", color:"#2A83E8"}}>{this.state.lng === "fr"?  "Notre solution": "Our solution"}</p>
                  </Zoom>
                  
                    <Zoom>
                      <p className="center" style={{fontSize: isBrowser? "3rem":"1rem",color:"black", textAlign:"center", width:"60%"}}>{this.state.lng === "fr"?  "Technologie de centre d'appels qui vous aide à comprendre et à répondre aux attentes des clients.": "Call Center Technology that Helps You Understand and Meet Customer Expectations"}</p>
                    </Zoom>

                      <div className='listsolution'>

                        <Bounce bottom>

                          <Card style={{ width: '350px', height:"700px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardsolution">                                               
                                                                                          
                            <Card.Body >

                                <img  src={process.env.PUBLIC_URL + 'sound-150x150.png'} style={{ width: '150px', height:"150px" }} className="center" alt="Unsaid"></img>

                                <p style={{fontSize:"30px", fontWeight:"bold", lineHeight:"45px", textAlign:"center", margin:"10px"}}>{this.state.lng === "fr"?  "Découvrir les sentiments derrière la voix grâce à notre analyse": "Uncover sentiment behind voice with our analysis"}</p>
                                                                          
                                <div className='row text-white d-flex '>

                                    <p style={{color:"#787878", fontSize:"20px", textAlign:"left",margin:'10px'}} >{this.state.lng === "fr"?  "Chaque conversation au sein de votre centre d'appels est analysée pour révéler les sentiments des clients. Fini les suppositions sur ce que ressentent les clients. Les mots et les sentiments des clients vous diront précisément ce qu'ils aiment et détestent.": "Every conversation at your call center is analyzed to uncover the sentiments of customers. No more guessing how customers feel. The words and the sentiments behind them will tell you exactly what they love and hate."}</p>

                                </div>
                            
                            </Card.Body>

                        </Card>   

                        <Card style={{ width: '350px', height:"700px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardsolution">                                               
                                                                                        
                            <Card.Body >

                                <img  src={process.env.PUBLIC_URL + 'cloud-150x150.png'} style={{ width: '150px', height:"150px" }} className="center" alt="Unsaid"></img>

                                <p style={{fontSize:"30px", fontWeight:"bold", lineHeight:"45px", textAlign:"center", margin:"10px"}}>{this.state.lng === "fr"?  "Basé sur le cloud pour une configuration facile": "Cloud Based = Easy Set Up"}</p>
                                                                          
                                <div className='row text-white d-flex '>

                                    <p style={{color:"#787878", fontSize:"20px", textAlign:"left",margin:'10px'}} >{this.state.lng === "fr"?  "Notre logiciel basé sur le cloud s'intègre facilement à votre CRM. Lorsque les données sur le sentiment des clients sont combinées avec vos systèmes, vos outils deviennent plus efficaces. Cela signifie que vous réaliserez plus de ventes et fidéliserez davantage de clients.": "Our cloud-based software easily integrates with your CRM. When data on customer sentiment is combined with your systems, your tools become more effective. This means you'll make more sales and retain more customers."}</p>

                                </div>
                            
                            </Card.Body>

                        </Card>   


                        <Card style={{ width: '350px', height:"700px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardsolution">                                               
                                                                                        
                            <Card.Body >

                                <img  src={process.env.PUBLIC_URL + 'brain-150x150.png'} style={{ width: '150px', height:"150px" }} className="center" alt="Unsaid"></img>

                                <p style={{fontSize:"30px", fontWeight:"bold", lineHeight:"45px", textAlign:"center", margin:"10px"}}>{this.state.lng === "fr"?  "Aperçus alimentés par l'IA": "AI-Powered Insights"}</p>
                                                                          
                                <div className='row text-white d-flex '>

                                    <p style={{color:"#787878", fontSize:"20px", textAlign:"left",margin:'10px'}} >{this.state.lng === "fr"?  "Notre IA puissante transforme chaque interaction en données concrètes qui peuvent être utilisées pour prendre des décisions commerciales éclairées. Vous aurez une vision précise de ce qui augmente ou diminue la satisfaction des clients.": "Our powerful AI turns every interaction into concrete data that can be used to make informed business decisions. You'll have an accurate view of what's increasing or decreasing customer satisfaction."}</p>

                                </div>
                            
                            </Card.Body>

                        </Card>   
  
                    </Bounce>

                  </div>

                  <div className="container" style={{marginTop:"60px"}}>

                    <div className="row">

                      {this.state.width<1000 ?

                        <Fade right>
                          <p style={{fontWeight:"bold", fontSize:"22px"}}>{this.state.lng === "fr"?  "Lorsque vous comprenez réellement ce que ressentent les gens, vous :": "When You Actually Understand How People Feel You:"}</p>                       
                        </Fade>

                        :

                        null
                        
                      }

                        <div className="col d-flex justify-content-center">

                          <Fade left>
                            <img  src={process.env.PUBLIC_URL + 'pexels-andrea-piacquadio-3760263-scaled-e1603197055126.jpg'} style={{ width: '350px', height:"350px",borderRadius:"25px" }} className="center" alt="Unsaid"></img>
                          </Fade>

                        </div>
                      
                     
                      <div className="col centercontent">

                        {this.state.width>1000 ?

                          <Fade right>
                            <p style={{fontWeight:"bold", fontSize:"22px"}}>{this.state.lng === "fr"?  "Lorsque vous comprenez réellement ce que ressentent les gens, vous :": "When You Actually Understand How People Feel You:"}</p>                       
                          </Fade>

                          :

                          null

                        }

                        <Fade right>
                          <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Gardez davantage de clients": "Keep More Customers"}</p>
                          <p style={{fontSize:"18px"}}>{this.state.lng === "fr"?  "Lorsqu'un client raccroche insatisfait, une analyse approfondie vous sera envoyée. Vous pourrez alors intervenir pour résoudre le problème avant que le client ne soit perdu à jamais. Cessez de perdre inutilement des clients et observez vos profits augmenter.": "When a customer gets off the phone unsatisfied, a detailed report will be sent to you. You'll be able to step in and fix the problem before the customer is lost forever. Stop needlessly losing customers and watch your profits increase."}</p>
                        </Fade>
                      </div>
                    </div>
                    
                  </div>

                  <hr className="lineseparator"></hr>  

                  <div className="container" style={{marginTop:"60px"}}>

                    <div className="row">

                      {this.state.width<1000 ?

                        <Fade left>
                          <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Prenez des décisions en toute confiance": "Make Decisions With Confidence"}</p> 
                        </Fade>

                        :

                        null
                        
                      }

                      {this.state.width<1000 ?
                      
                        <div className="col d-flex justify-content-center">
                          <Fade right>
                            <img  src={process.env.PUBLIC_URL + 'meeting.jpg'} style={{ width: '350px', height:"350px",borderRadius:"25px" }} className="center" alt="Unsaid"></img>
                          </Fade>
                        </div> 

                        :

                        null
                        
                      }

                      {this.state.width<1000 ?

                        <Fade left>
                          <p style={{fontSize:"18px"}}>{this.state.lng === "fr"?  "Avec les informations fournies par l'IA, vous saurez exactement quels produits ou services les gens adorent et lesquels les laissent insatisfaits. Prenez des décisions commerciales en toute confiance, sachant qu'elles sont étayées par des données provenant de la source la plus précise : vos clients.": "With the insights provided by AI, you'll know exactly which products or services people love and which are leaving them unsatisfied. Make business decisions with confidence, knowing they are backed up by data from the most accurate source: your customers."}</p>
                        </Fade>
                        
                        :

                        null
                        
                      }

                      {this.state.width>1000 ?
                        
                        <div className="row">
                            
                            <div className="col centercontent">  
                              <Fade left>                        
                                <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Prenez des décisions en toute confiance": "Make Decisions With Confidence"}</p> 
                                <p style={{fontSize:"18px"}}>{this.state.lng === "fr"?  "Avec les informations fournies par l'IA, vous saurez exactement quels produits ou services les gens adorent et lesquels les laissent insatisfaits. Prenez des décisions commerciales en toute confiance, sachant qu'elles sont étayées par des données provenant de la source la plus précise : vos clients.": "With the insights provided by AI, you'll know exactly which products or services people love and which are leaving them unsatisfied. Make business decisions with confidence, knowing they are backed up by data from the most accurate source: your customers."}</p>
                              </Fade>
                            </div>


                            <div className="col d-flex justify-content-center">
                              <Fade right>
                                <img  src={process.env.PUBLIC_URL + 'meeting.jpg'} style={{ width: '350px', height:"350px",borderRadius:"25px" }} className="center" alt="Unsaid"></img>
                              </Fade>
                            </div>
                          
                        
                          
                        </div>

                        :

                        null
                        
                      }
                      
                    </div>

                  </div>

                  <hr className="lineseparator"></hr>

                  <div className="container" style={{marginTop:"60px"}}>

                    <div className="row">

                      {this.state.width<1000 ?
                          <Fade right>
                            <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Renforcez la fidélité": "Increase Loyalty"}</p>
                          </Fade>
                        :

                        null
                        
                      }

                        <div className="col d-flex justify-content-center">
                          <Fade left>
                            <img  src={process.env.PUBLIC_URL + 'loyal-customers-scaled.jpg'} style={{ width: '350px', height:"350px",borderRadius:"25px" }} className="center" alt="Unsaid"></img>
                          </Fade>
                        </div>
                      
                     
                      <div className="col centercontent">
                        
                        {this.state.width>1000 ?

                          <Fade right>
                            <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Renforcez la fidélité": "Increase Loyalty"}</p>
                          </Fade>

                          :

                          null

                        }

                        {this.state.width>1000 ?

                          <Fade right>
                            <p style={{fontSize:"18px"}}>{this.state.lng === "fr"?  "Grâce a la compréhension des ressentis de vos clients, vos agents sont équipés pour mieux les servir et les fidéliser pour le long terme.": "By understanding your customers' feelings, your agents are equipped to better serve them and retain them for the long term."}</p>
                          </Fade>

                          :

                          null

                        }

                      </div>

                      {this.state.width<1000 ?

                      <p style={{fontSize:"18px"}}>{this.state.lng === "fr"?  "Grâce a la compréhension des ressentis de vos clients, vos agents sont équipés pour mieux les servir et les fidéliser pour le long terme.": "By understanding your customers' feelings, your agents are equipped to better serve them and retain them for the long term."}</p>

                      :

                      null

                      }

                    </div>
                    
                  </div>

                  <hr className="lineseparator"></hr>

                    <Zoom bottom>
                      <div style={{textAlign:"center", marginTop:"60px"}}>
                        <p style={{fontWeight:"bold", fontSize:"40px"}}>{this.state.lng === "fr"?  "Les sentiments sont complexes.": "Sentiments are complicated."}</p>
                        <p style={{fontWeight:"bold", fontSize:"60px"}}>{this.state.lng === "fr"?  "Commencer à utiliser notre logiciel.": "Getting started using our software."}</p>
                        <p style={{fontWeight:"bold", fontSize:"30px"}}>{this.state.lng === "fr"?  "Voici comment cela fonctionne :": "Here's how it works:"}</p>
                        <p style={{ fontWeight:"bold",  fontSize:"24px"}}>{this.state.lng === "fr"?  "1 - Réservez une démonstration gratuite": "1 - Book a Free Demo"}</p>
                        <p style={{ fontSize:"24px"}}>{this.state.lng === "fr"?  "Découvrez toutes les informations étonnantes que vous pouvez obtenir sur vos clients.": "Discover all the amazing insights you can get into your customers."}</p>
                        <p style={{ fontWeight:"bold",  fontSize:"24px", marginTop:"40px"}}>{this.state.lng === "fr"?  "2 - Choisir un abonnement": "2- Choose a Plan"}</p>
                        <p style={{ fontSize:"24px"}}>{this.state.lng === "fr"?  "Choisissez l'abonnement mensuel qui convient à votre entreprise.": "Pick the monthly subscription that's right for your business."}</p>
                        <p style={{ fontWeight:"bold",  fontSize:"24px", marginTop:"40px"}}>{this.state.lng === "fr"?  "3 - Rendre les clients heureux": "3 - Make Customers Happy"}</p>
                        <p style={{ fontSize:"24px"}}>{this.state.lng === "fr"?  "Offrez à vos clients ce qu'ils veulent en agissant sur les informations fournies par l'IA.": "Give your customers what they want by taking action on the insights provided by AI."}</p>
                      </div>
                    </Zoom>    

                  <hr className="lineseparator" ></hr>

                  <div style={{height:"50px"}}></div>
      
                </div>   

                <div id="tarification">
                  <Zoom>
                    <p style={{fontWeight:"bold", fontSize:"50px", textAlign:"center", color:"#2A83E8"}}>{this.state.lng === "fr"?  "Tarification": "Pricing"}</p>
                  </Zoom>
                  <div className='listsolution'>

                    <Bounce bottom>

                      <Card style={{ width: '350px', height:"640px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardtarif">                                               
                                                                                      
                        <Card.Body >

                            <div  className='typeabonnement' style={{color:"#CD7F32", fontSize:"30px", textAlign:"center", paddingTop:"35px"}}>Starter* - 1 agent </div>

                            <div  className='timeabonnement' >
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "Essai gratuit": "Free trial"}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "de 30 minutes.": "to 30 minutes."}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "sur 1 mois": "for one month"}</p>
                            </div>

                            <div className='abonnementdetails'>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "fr"?  "Max 3 150 min / mois": "Max 3 150 minutes / month"}</p>
                              {/*<hr className="lineseparatorabonnement" ></hr>*/}
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Accès Web et mobile": "Web and mobile access"}</p>
                           
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Transcription ": "Transcription"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Analyse de sentiment": "Sentiment Analysis"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "IA générative": "Generative AI"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Hyper personnalisation": "Hyper personalization"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Résumé d'appel": "Call summarization"}</p>
                              
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Rapport PDF": "PDF report"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Cloud": "Cloud"}</p>
                            
                            </div>
                            
                            <div className='boutonsubscribe' onClick={() => this.typeabonnement(1)}>
                              <p  style={{color:"white", fontSize:"30px", textAlign:"center", paddingTop:"10px"}}>{this.state.lng === "fr"?  "Chiffrage sur devis": "Costing on quote"}</p>
                            </div>
                        
                        </Card.Body>

                    </Card>   

                    <Card style={{ width: '350px', height:"640px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardtarif">                                               
                                                                                      
                        <Card.Body >

                            <div  className='typeabonnement' style={{color:"#2A83E8", fontSize:"30px", textAlign:"center", paddingTop:"35px"}}>Business* – 5 agents</div>

                            <div  className='timeabonnement' >
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "Essai gratuit": "Free trial"}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "de 30 minutes.": "to 30 minutes."}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "sur 1 mois": "for one month"}</p>
                            </div>

                            <div className='abonnementdetails'>

                            <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "fr"?  "Max 15 750 min / mois": "Max 15 750 minutes / month"}</p>
                              {/*<hr className="lineseparatorabonnement" ></hr>*/}
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Accès Web et mobile": "Web and mobile access"}</p>
                           
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Transcription ": "Transcription"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Analyse de sentiment": "Sentiment Analysis"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "IA générative": "Generative AI"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Hyper personnalisation": "Hyper personalization"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Résumé d'appel": "Call summarization"}</p>
                              
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Rapport PDF": "PDF report"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Cloud": "Cloud"}</p>
                              
                            </div>
                            
                            <div className='boutonsubscribe' onClick={() => this.typeabonnement(2)}>
                                <p  style={{color:"white", fontSize:"30px", textAlign:"center", paddingTop:"10px"}}>{this.state.lng === "fr"?  "Chiffrage sur devis": "Costing on quote"}</p>
                            </div>
                        
                        </Card.Body>

                    </Card>   

                    <Card style={{ width: '350px', height:"640px", borderRadius:'25px', margin:isBrowser?'25px' :'0px', marginTop:isMobile?'25px' :'25px' }} className="cardtarif">                                               
                                                                                      
                        <Card.Body >

                            <div  className='typeabonnement' style={{color:"#FFD700", fontSize:"30px", textAlign:"center", paddingTop:"0px"}}>{this.state.lng === "fr"?  <p><p>Entreprise *</p><p>Offre personnalisée</p></p>: <p><p>Enterprise *</p><p>Personalized offer</p></p>}</div>

                            <div  className='timeabonnement' >
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "Essai gratuit": "Free trial"}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "de 30 minutes.": "to 30 minutes."}</p>
                              <p style={{color:"white", fontWeight:"bold",fontSize:"24px", textAlign:"center"}}>{this.state.lng === "fr"?  "sur 1 mois": "for one month"}</p>
                            </div>

                            <div className='abonnementdetails'>

                            <p style={{fontSize:"16px", textAlign:"center", marginTop:"10px"}}>{this.state.lng === "fr"?  "Nbre min / mois personnalisé": "Personalized min / month"}</p>
                              {/*<hr className="lineseparatorabonnement" ></hr>*/}
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Accès Web et mobile": "Web and mobile access"}</p>
                           
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Transcription ": "Transcription"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Analyse de sentiment": "Sentiment Analysis"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "IA générative": "Generative AI"}</p>

                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Hyper personnalisation": "Hyper personalization"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Résumé d'appel": "Call summarization"}</p>
                              
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Rapport PDF": "PDF report"}</p>
                             
                              <p style={{fontSize:"16px", textAlign:"center", marginTop:"-10px"}}>{this.state.lng === "fr"?  "Cloud": "Cloud"}</p>
                            </div>
                            
                            <div className='boutonsubscribe' onClick={() => this.typeabonnement(3)}>
                                    <p  style={{color:"white", fontSize:"30px", textAlign:"center", paddingTop:"10px"}}>{this.state.lng === "fr"?  "Devis personnalisé": "Personalized quote"}</p>
                                </div>
                        
                        </Card.Body>

                    </Card>   

                    </Bounce>

                    </div>

                </div>

                <div id="contact">
                  <Zoom>
                    <div className="container" >

                      <div className={this.state.width>1000 ?"row":"column"}>

                          <div className="col">

                              <div  style={{margin:'5px'}}>
                                <p style={{color:"#2A83E8", fontWeight:"bold", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'30px':"26px"}}>{this.state.lng === "fr"?  "Contactez-nous": "Contact us"}</p>
                              </div>

                              <div  style={{margin:'5px'}}>
                                <input type="text"  style={{color:"black", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder={this.state.lng === "fr"?  "Noms et prénoms": "Name and lastname"} value={this.state.nomcontact} onChange={(e) => this.setState({ nomcontact: e.target.value})} />
                              </div>

                              <div  style={{margin:'5px'}}>
                                <input type="text"  style={{color:"black", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder={this.state.lng === "fr"?  "Numéro de téléphone": "Telephone number"} ref="Telephonesend" value={this.state.telephonecontact} onChange={(e) => this.setState({ telephonecontact: e.target.value})} />
                              </div>

                              <div  style={{margin:'5px'}}>
                                <input type="email"  style={{ccolor:"black", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder="Email" ref="Emailsend" value={this.state.mailcontact} onChange={(e) => this.setState({ mailcontact: e.target.value})} />
                              </div>

                              <div style={{margin:'5px'}}>
                                <input type="text"  style={{color:"black", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'18px':"14px"}} placeholder={this.state.lng === "fr"?  "Sujet": "Subject"} ref="Objetsend" value={this.state.titrecontact} onChange={(e) => this.setState({ titrecontact: e.target.value})}/>
                              </div>

                              <div style={{margin:'5px'}}>
                                <label >{this.state.lng === "fr"?  "Type d'abonnement": "Subscription Type"}</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} id="typeabonnement" value={this.state.typeabonnement} onChange={this.selecttypeabonnement}>
                                      {this.state.listtypeabonnement.map((tpa) => (
                                        <option value={tpa.value}>{this.state.lng === "fr"? tpa.typeabonnementfr:tpa.typeabonnementen}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                              </div>

                              <div style={{margin:'5px'}}>
                                <label >{this.state.lng === "fr"?  "Centre d'appel": "Call center"}</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} id="typecallcenter" value={this.state.typecallcenter} onChange={this.selecttypecallcenter}>
                                      {this.state.listtypecallcenter.map((tca) => (
                                        <option value={tca.value}>{this.state.lng === "fr"? tca.callcenterfr:tca.callcenteren}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                              </div>

                              <div style={{margin:'5px'}}>
                                <label >{this.state.lng === "fr"?  "Etes-vous interessé par un live demo?": "Are you interested in a live demo session?"}</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} id="typelivedemo" value={this.state.typelivedemo} onChange={this.selecttypelivedemo}>
                                      {this.state.listtypelivedemo.map((tld) => (
                                        <option value={tld.value}>{this.state.lng === "fr"? tld.livedemofr:tld.livedemoen}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                              </div>

                              <div style={{margin:'5px'}}>
                                <label >{this.state.lng === "fr"?  "Etes-vous interessé par la solution de centre d'appel Unsaid?": "Are you interested in Unsaid Call center solution?"}</label>
                                  <div>
                                    <Form.Select style={{height:'30px', paddingTop:'0px', paddingLeft:'10px' }} id="typesolution" value={this.state.typesolution} onChange={this.selecttypesolution}>
                                      {this.state.listtypesolution.map((ts) => (
                                        <option value={ts.value}>{this.state.lng === "fr"? ts.typesolutionfr:ts.typesolutionen}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                              </div>

                              <div  style={{margin:'5px'}}>
                                <textarea type="textarea" style={{color:"black", width:"100%",padding:"10px",fontSize:isBrowser?'18px':"14px"}} rows={5} placeholder={this.state.lng === "fr"?  "Votre message ...": "Your message ..."} ref="Messagesend" value={this.state.messagecontact} onChange={(e) => this.setState({ messagecontact: e.target.value})} />
                              </div>

                              <div className="container">
                                <div className="row ">
                                  <div className="col">
                                    
                                    <div className="form-group d-flex flex-row justify-content-center" style={{margin:'10px', height:"40px"}}>
                                      <input type="text"  style={{width:'160px',height:"40px", fontSize:'18px',textAlign:"center"}} placeholder={this.state.lng === "fr"?  "Réécrire le captcha": "Rewrite captcha"} value={this.state.captcha} onChange={(e) => this.setState({ captcha: e.target.value})} />
                                    </div>
                                  
                                  </div>
                                  <div className="col d-flex justify-content-center">
                                    <div style={{background: '#282c34',
                                            border: 0,
                                            borderRadius: 8,
                                            width:'160px', height:'40px', marginTop:'10px'}}>
                                      <p className="noselect" style={{fontWeight:'bold', fontSize:'20px',paddingTop:"5px",color:"white",textAlign:'center'}}>{this.state.generatecaptcha}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div className={this.state.width>1000 ?"d-flex flex-row justify-content-center":"d-flex justify-content-center"}>
                                  <button style={{background: '#2A83E8',marginTop:"10px",border: 0,borderRadius: 24,width:'40%', height:'50px', color:'white', fontSize:"18px"}}  onClick={()=> this.envoiemail()}>{this.state.lng === "fr"?  "Envoyer": "Send"}</button>
                              </div>
                              
                          </div>
                        
                      
                        <div className="col">
                         
                          
                          <p style={{fontWeight:"bold", fontSize:"40px", marginTop: this.state.width>1000 ? "10px":"50px" }}>{this.state.lng === "fr"?  "Contact": "Contact"}</p>

                          <div className='d-flex flex-row' onClick={(e) => {e.preventDefault(); window.location.href="tel:+330615923357";}}>
                            <div className='btncontactinfo'style={{marginTop:"20px"}}><PhoneIphoneIcon style={{fontSize:'25',color:"white"}}/></div>
                            <p style={{marginTop:"10px",fontWeight:"bold", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'26px':"22px"}} >Mobile</p>
                          </div>
                          <p style={{marginLeft:"60px"}} onClick={(e) => {e.preventDefault(); window.location.href="tel:+330615923357";}}>+33 06 15 92 33 57</p>

                          <div className='d-flex flex-row' onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@unsaid.fr";}}>
                            <div className='btncontactinfo'style={{marginTop:"20px"}}><MailOutlineIcon style={{fontSize:'25',color:"white"}}/></div>
                            <p style={{marginTop:"10px",fontWeight:"bold", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'26px':"22px"}} >Email</p>
                          </div>
                          <p style={{marginLeft:"60px"}} onClick={(e) => {e.preventDefault(); window.location.href="mailto:contact@unsaid.fr";}}>contact@unsaid.fr</p>

                          <div className='d-flex flex-row' onClick={(e) => {e.preventDefault(); window.open("https://www.google.com/maps/place/Unsaid/@48.8660188,2.329429,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66f860dc10043:0xfa6309b84bee5fca!8m2!3d48.8660188!4d2.329429!16s%2Fg%2F11qws2t_yq?hl=en-FR&entry=ttu","_blank")}}>
                            <div className='btncontactinfo'style={{marginTop:"20px"}}><PlaceIcon style={{fontSize:'25',color:"white"}}/></div>
                            <p style={{marginTop:"10px",fontWeight:"bold", width:"100%", height:"40px",padding:"10px", fontSize:isBrowser?'26px':"22px"}} >229 rue Saint honoré, 75001 Paris, France</p>
                          </div>

                        </div>

                      </div>

                    </div>
                  </Zoom>
                </div>

                <div id="footer">

                  <div className='d-flex justify-content-center'>
                    <img src={process.env.PUBLIC_URL +'logounsaid.png'} style={{width:"100px", height:"62.5px", marginBottom:"30px"}} alt="Unsaid"/>
                  </div>

                  <div className='d-flex flex-row justify-content-center'>
                    
                    <Fade left>
                      <Nav.Link href="https://www.linkedin.com/company/unsaid-ai" target="_blank" ><LinkedInIcon style={{fontSize:'30', color: 'white',marginBottom:6}}/></Nav.Link>
                      <Nav.Link href="https://www.youtube.com/channel/UCA5BDGMytHfnK19bhAD_vVg" target="_blank"><YouTubeIcon style={{fontSize:'30', color: 'white', marginBottom:6}}/></Nav.Link>
                    </Fade>
                    <Nav.Link href="https://www.facebook.com/unsaidai" target="_blank"><FacebookIcon style={{fontSize:'30' , color: 'white', marginBottom:6}}/></Nav.Link>
                    <Fade right>
                      <Nav.Link href="https://www.instagram.com/unsaid_ai/" target="_blank"><InstagramIcon style={{fontSize:'30', color: 'white', marginBottom:6}}/></Nav.Link>
                      <Nav.Link href="https://twitter.com/unsaid_ai" target="_blank"><img  src={process.env.PUBLIC_URL + 'twitterxwhite.png'} style={{ width: '26px', height:"26px", marginTop:3}} className="center" alt="Unsaid"></img></Nav.Link>
                    </Fade>
                  </div>

                  <div style={{height:"82px"}} className="d-flex justify-content-center">
                    <Flip bottom>                    
                      <p style={{ color:'white',fontSize:"16px", textAlign:"center", marginTop:"22px"}}>
                          Copyright &copy; {new Date().getFullYear()} {this.state.lng === "fr"?  "Tous les droits réservés à ": "All rights reserved to "}Unsaid.
                      </p>
                    </Flip>
                  </div>
                
                </div>
                
                {this.state.scrollpage>200 ?

                  <Zoom>
                    <button className="btntop" onClick={() => this.scrolltop()} style={{width:'50px', height:'50px', marginBottom:this.state.width<1000 ?"170px":"50px",padding:"0%"}}><span><Fade bottom forever={true} duration={2000}> <KeyboardDoubleArrowUpIcon /> </Fade></span></button>
                  </Zoom>

                  :

                  null

                }
                        
            </div> 

          );  
  
      }

}

export default App;
